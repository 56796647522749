<template>
    <tr
        ref="additionalRow"
        @click="emitBalance($event)"
        class="balance-assets-modal-toggle"
        :class="s.expand"
    >
        <td v-if="isAggregatedByPlacements" :class="[theme.table.alignLeft, theme.aggregatedbalances.bigText]">
            <div :class="s.row">
                <CryptoIcon :class="[s.middle, s.mrXs, s.mlM]" :symbol="placementName" :size="14" />
                <span :class="[s.middle, s.mrXs, s.bold]">{{ placementName }}</span>
                <span :class="[s.middle, theme.aggregatedbalances.valueText, theme.aggregatedbalances.gray]" v-if="placement.blockchainName">{{ placement.blockchainName }}</span>
                <span :class="[s.middle, theme.aggregatedbalances.valueText, theme.aggregatedbalances.gray]" v-if="placement.assetType && placement.assetType === 'fiat'">Fiat Currency</span>
            </div>
        </td>
        <td v-else :class="[theme.table.alignLeft, theme.aggregatedbalances.bigText]">
            <div :class="s.placementRow">
                <PlacementIcon :class="[s.middle, s.mrXs, s.mlM]" :placement="placementName" :size="14" />
                <span :class="[s.middle, s.mrXs, s.bold]">{{ placementName.toUpperCase() }}</span>
                <span :class="[s.middle, theme.aggregatedbalances.valueText, theme.aggregatedbalances.gray]" v-if="blockchainTitle">{{ blockchainTitle }}</span>
                <span :class="[s.middle, theme.aggregatedbalances.valueText, theme.aggregatedbalances.gray]" v-if="placement.assetType && placement.assetType === 'fiat'">Fiat Currency</span>
                <div
                    v-if="getIsPlacementUnified(placementName)"
                    :class="s.unifiedWrapper"
                >
                    <span>
                        Unified
                    </span>
                </div>
            </div>
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.gray]">
            {{
                Number(placement.total) === 0
                    ? 0
                    : Number(placement.total.floor(assetPrecision)) === 0
                        ? leastBalance
                        : placement.total.floor(assetPrecision).getSeparatedDigits()
            }}
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.green]">
            {{
                Number(placement.free) === 0
                    ? 0
                    : Number(placement.free.floor(assetPrecision)) === 0
                        ? leastBalance
                        : placement.free.floor(assetPrecision).getSeparatedDigits()
            }}
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.red]">
            {{
                Number(summaryHold) === 0
                    ? 0
                    : Number(summaryHold.floor(assetPrecision)) === 0
                        ? leastBalance
                        : summaryHold.floor(assetPrecision).getSeparatedDigits()
            }}
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.black]">
            {{ totalEquivalentSummary }}
        </td>
        <td></td>
        <td v-if="showShortGraph"></td>
        <td v-if="showShortGraph"></td>
        </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import theme from 'Theme';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';

/*
* emits
* show-modal: () => Balance;
* */

export default {
    name: 'PlacementBalanceRow',
    props: {
        placementName: {
            type: String,
            required: true,
        },
        placement: {
            type: [Object, Array],
            required: true,
        },
        totalScore: {
            type: Number,
            required: true,
        },
        assetPrecision: {
            type: Number,
            required: true,
        },
        showShortGraph: {
            type: Boolean,
            default: false,
        },
        blockchainTitle: {
            type: String,
            default: '',
        },
        totalEquivalent: {
            type: Number,
            required: true,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
            leastBalance: '< 0.00000001',
            theme,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        summaryHold() {
            if (this.isAggregatedByPlacements) {
                return this.placement.hold + this.placement.positionsHold;
            }
            if (!this.placement.positionsHold) {
                return Number(this.placement.hold);
            }
            return Number(this.placement.hold) + Number(this.placement.positionsHold);
        },
        totalEquivalentSummary() {
            if (this.isAggregatedByPlacements) {
                const value = this.placement.totalEquivalent.floor(this.quotationAssetPrecision).getSeparatedDigits();
                if (Number(value) === 0) {
                    if (this.quotationAssetPrecision === 2) {
                        return `< ${this.quotationAssetCharacter}0.01`;
                    }
                    return `< ${this.quotationAssetCharacter}0.00000001`;
                }
                return `${this.quotationAssetCharacter}${value}`;
            }
            const value = this.totalEquivalent.floor(this.quotationAssetPrecision).getSeparatedDigits();
            if (Number(value) === 0) {
                if (this.quotationAssetPrecision === 2) {
                    return `< ${this.quotationAssetCharacter}0.01`;
                }
                return `< ${this.quotationAssetCharacter}0.00000001`;
            }
            return `${this.quotationAssetCharacter}${value}`;
        },
    },
    methods: {
        getIsPlacementUnified(placementName) {
            return UnifiedExchangesTags.has(
                this.$store.state.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase()),
            );
        },
        emitBalance(e) {
            if (this.isAggregatedByPlacements) {
                this.$emit('show-modal', { ...this.placement, x: e.offsetX, y: this.$refs.additionalRow.offsetTop + e.offsetY });
            } else {
                this.$emit('show-modal', { ...this.placement.serialize(), x: e.offsetX, y: this.$refs.additionalRow.offsetTop + e.offsetY });
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.expand {
    background-color: var(--cl-expand);
    cursor: pointer;
    & td {
        height: 60px;
    }
    & .middle {
        vertical-align: middle;
    }
    & .mrXs {
        margin-right: var(--m-xs);
    }
    & .mlM {
        margin-left: var(--m-m);
    }
    & .modal {
        position: absolute;
    }
    & .bold {
        font-weight: var(--fw-bold);
    }
    & .row {
        display: flex;
        align-items: center;
        column-gap: var(--m-xs);
    }
    & .placementRow {
        display: flex;
        align-items: center;
    }
    .unifiedWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: fit-content;
        background-color: var(--cl-gray);
        border-radius: 100px;
        padding: var(--m-ss) var(--m-s);
        font-size: var(--fs-xxs);
        font-weight: var(--fw-bold);
        color: var(--cl-white);
        text-align: center;
        line-height: normal;
        margin-left: var(--m-xs);
    }
}
</style>
