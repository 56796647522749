<template>
    <LegendBlock
        :title="title"
        :from-account="account"
    >
        <ul v-if="items && items.length">
            <li
                v-for="(item, index) in items"
                :key="item.id"
                :class="[
                    s.row,
                    s.text,
                    s.pointer,
                    {
                        [s.disabled]: item.disabled || item.isBlocked || item.id === disabledItemId || (!item.isEnabled && needCheckIsEnabled) || (!item.isPlacementLinked && needCheckIsEnabled),
                        [s.active]: isActiveItem(index),
                        [s.notAllowed]: (item.disabled || item.isBlocked || (!item.isEnabled && needCheckIsEnabled) || (!item.isPlacementLinked && needCheckIsEnabled)) && item.id !== disabledItemId,
                    },
                ]"
                @click="item.disabled || item.isBlocked || (!item.isEnabled && needCheckIsEnabled) || ((!item.isPlacementLinked && needCheckIsEnabled)) ? false : item.id === disabledItemId ? switchActiveItems(item, index) :  setActiveItem(index, item)"
            >
                <slot name="item" :item-data="item">
                    <span>
                      {{ item }}
                    </span>
                </slot>
                <div
                    v-if="item.isUnified"
                    :class="s.unifiedWrapper"
                >
                    <span>
                        Unified
                    </span>
                </div>
                <Icon
                    :class="[s.violet, { [s.marginTop]: isAccountsList }]"
                    icon="check"
                    v-if="isActiveItem(index)"
                    small
                />
                <Icon
                    icon="wrench"
                    v-else-if="!item.isEnabled && needCheckIsEnabled"
                    :class="[s.clock, s.icon]"
                />
                <Icon
                    icon="link"
                    v-else-if="!item.isPlacementLinked && needCheckIsEnabled"
                    :class="[s.link, s.icon]"
                    @clicked="linkPlacement(item.name)"
                />
                <Icon
                    icon="clock"
                    v-else-if="getIsPlacementPending(item.name) && needCheckIsEnabled"
                    :class="[s.link, s.icon]"
                />
            </li>
        </ul>
        <NoData v-else />
    </LegendBlock>
</template>

<script>
import Icon from 'UI/Icon.vue';
import NoData from 'UI/NoData.vue';
import { SET_PLACEMENT_NAME } from 'Store/v2/LinkPlacement';

import LegendBlock from './LegendBlock.vue';

export default {
    name: 'ListSelectBox',
    components: { NoData, Icon, LegendBlock },
    props: {
        items: {
            type: [Array, Object],
            default: () => [],
        },
        title: {
            type: String,
            default: null,
        },
        val: {
            type: Number,
            default: 0,
        },
        isSorted: {
            type: Boolean,
            default: false,
        },
        disabledItemId: {
            type: Number,
            default: -1,
        },
        needCheckIsEnabled: {
            type: Boolean,
            default: false,
        },
        isAccountsList: {
            type: Boolean,
            default: false,
        },
        account: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            active: 0,
        };
    },
    methods: {
        getIsPlacementPending(name) {
            return this.$store.getters['Accounts/isPlacementPending'](name);
        },
        setActiveItem(index, item) {
            if (this.active !== index) {
                this.active = index;
                if (typeof item.id === 'string') {
                    this.$emit('input', { id: item.id, index });
                    this.$emit('change', { id: item.id, index });
                } else {
                    this.$emit('input', { id: item.name, index });
                    this.$emit('change', { id: item.name, index });
                }
            }
        },
        switchActiveItems(item, index) {
            this.$emit('switch', { item, index });
        },
        isActiveItem(index) {
            return this.active === index;
        },
        async linkPlacement(placementName) {
            const placement = this.$store.state.Placements.placements.find((p) => p.name.toLowerCase() === placementName.toLowerCase());
            if (placement && placement.needExternalKyc) {
                this.$store.commit(SET_PLACEMENT_NAME(placementName));
                this.$modal.show('confirmLinkModal');
            } else {
                await this.$store.dispatch('Accounts/linkPlacement', placementName);
            }
        },
    },
    mounted() {
        this.setActiveItem(this.val, this.items[this.val]);
        if (typeof this.items[this.val].id === 'string') {
            this.$emit('input', { id: this.items[this.val].id, index: this.val });
            this.$emit('change', { id: this.items[this.val].id, index: this.val });
        } else {
            this.$emit('input', { id: this.items[this.val].name, index: this.val });
            this.$emit('change', { id: this.items[this.val].name, index: this.val });
        }
    },
    watch: {
        val(value) {
            this.setActiveItem(value, this.items[value]);
        },
    },
};
</script>

<style lang="postcss" module="s">
.pointer {
    cursor: pointer;
}
.disabled {
    & div:first-child {
        opacity: .4;
    }
}
.notAllowed {
    cursor: not-allowed;
}
.row {
    display: flex;
    margin-bottom: var(--m-m);
    column-gap: var(--m-xs);
}
.row:last-child {
    margin-bottom: 0;
}
.violet {
    color: var(--cl-violet);
    &.marginTop {
        margin-top: var(--m-xs);
    }
}
.text {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-black);
    text-transform: uppercase;
    &.active {
        font-weight: var(--fw-extrabold);
        color: var(--cl-violet);
    }
}
.link {
    margin-left: auto;
    color: var(--cl-violet);
    cursor: pointer;
}
.clock {
    margin-left: auto;
    color: var(--cl-gray);
}
.wrench {
    margin-left: auto;
    color: var(--cl-gray);
}
.icon {
    opacity: 1;
}
.unifiedWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: var(--cl-gray);
    border-radius: 100px;
    padding: var(--m-ss) var(--m-s);
    font-size: var(--fs-xxs);
    font-weight: var(--fw-bold);
    color: var(--cl-white);
    text-align: center;
    line-height: normal;
}
</style>
