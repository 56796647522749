<template>
    <tbody :class="theme.aggregatedbalances.balance">
    <tr :class="s.pointer" @click="toggleDetails">
        <td v-if="isAggregatedByPlacements" :class="[theme.table.alignLeft, theme.aggregatedbalances.bigText]">
            <div :class="s.row">
                <PlacementIcon
                    :placement="balance.placementName"
                    :size="14"
                    class="cryptoicon"
                    :class="[s.middle, s.mrXs]"
                />
                <span :class="[s.middle, s.mrXs, s.bold]">{{ balance.placementName.toUpperCase() }}</span>
                <Icon icon="arrow_right" :class="[s.middle, s.icon, { [s.rotated]: show }]" />
                <div
                    v-if="getIsPlacementUnified(balance.placementName)"
                    :class="s.unifiedWrapper"
                >
                    <span>
                        Unified
                    </span>
                </div>
            </div>
        </td>
        <td v-else :class="[theme.table.alignLeft, theme.aggregatedbalances.bigText]">
            <div :class="s.row">
                <CryptoIcon
                    :symbol="balance.assetSymbol"
                    :size="14"
                    class="cryptoicon"
                    :class="[s.middle, s.mrXs]"
                />
                <span :class="[s.middle, s.mrXs, s.bold]">{{ balance.assetSymbol }}</span>
                <Icon icon="arrow_right" :class="[s.middle, s.icon, { [s.rotated]: show }]" />
            </div>
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.gray]">
            {{ isAggregatedByPlacements ? `${balancePlacements.length} assets` : total.floor(assetPrecision).getSeparatedDigits() }}
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.green]">
            {{ free.floor(assetPrecision).getSeparatedDigits() }}
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.red]">
            {{ summaryHold.floor(assetPrecision).getSeparatedDigits() }}
        </td>
        <td :class="[theme.table.alignCenter, theme.aggregatedbalances.valueText, theme.aggregatedbalances.black]">
            {{ totalEquivalentSummary }}
        </td>
        <td width="35%" :class="[theme.table.alignCenter, s.w25]">
            <PlacementsBalances
                :placements="isAggregatedByPlacements ? truncateBalancesArray(balance.assetsBalances) : truncateBalancesObject(balance.placements)"
                :total-score="total"
                :is-aggregated-by-placements="isAggregatedByPlacements"
            />
        </td>
        <td width="100px" :class="theme.table.alignCenter" v-if="showShortGraph">
            <ShortChart :balance="balance" />
        </td>
        <td width="100px" v-if="showShortGraph">
            <QuotationShortChart :balance="balance" />
        </td>
    </tr>
    <template v-if="show">
        <PlacementBalanceRow
            v-for="(placementData, index) in balancePlacements"
            :key="`${isAggregatedByPlacements ? placementData.assetSymbol : placementData.placementName}${index}`"
            :placement-name="isAggregatedByPlacements ? placementData.assetSymbol : placementData.placementName"
            :placement="placementData"
            :total-score="isAggregatedByPlacements ? placementData.total : balance.total"
            :total-equivalent="isAggregatedByPlacements ? placementData.totalEquivalent : getBalanceTotalEquivalent(placementData)"
            :asset-precision="assetPrecision"
            :show-short-graph="showShortGraph"
            :blockchain-title="placementData.placementName === 'Single Broker' ? placementData.blockchainName : ''"
            :is-aggregated-by-placements="isAggregatedByPlacements"
            @show-modal="emitShowModal($event)"
        />
    </template>
    </tbody>
</template>

<script>
import { mapGetters } from 'vuex';

import ShortChart from 'Components/Portfolios/components/CurrentBalanceShortChart.vue';
import QuotationShortChart from 'Components/Portfolios/components/CurrentBalanceQuotationShortChart.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';

import PlacementBalanceRow from './PlacementBalanceRow.vue';
import PlacementsBalances from './PlacementsBalances.vue';

export default {
    name: 'CurrentBalanceRow',
    components: {
        PlacementsBalances,
        PlacementBalanceRow,
        ShortChart,
        QuotationShortChart,
        Icon,
    },
    props: {
        balance: {
            type: Object,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            default: () => {},
        },
        showShortGraph: {
            type: Boolean,
            default: false,
        },
        maxListPrecision: {
            type: Number,
            default: null,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        balancePlacements() {
            if (this.isAggregatedByPlacements) {
                return [...this.balance.assetsBalances].sort(({ totalEquivalent: a }, { totalEquivalent: b }) => b - a);
            }
            const result = Object.values(this.balance.placements);
            result.sort((a, b) => {
                const platformName = 'Single Broker';
                if (a.placementName === platformName && b.placementName !== platformName) {
                    return -1;
                }
                if (b.placementName === platformName && a.placementName !== platformName) {
                    return 1;
                }
                const quotationIndexA = a.quotationAssetSymbols?.findIndex((el) => el === this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']);
                const quotationIndexB = b.quotationAssetSymbols?.findIndex((el) => el === this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']);
                if (typeof quotationIndexA === 'number' && quotationIndexA !== -1 && typeof quotationIndexB === 'number' && quotationIndexB !== -1) {
                    const quotedTotalA = Number(a.quotations[quotationIndexA]) * Number(a.total);
                    const quotedTotalB = Number(b.quotations[quotationIndexB]) * Number(b.total);
                    if (quotedTotalA > quotedTotalB) {
                        return -1;
                    }
                    if (quotedTotalB > quotedTotalA) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });
            return result;
        },
        totalEquivalents() {
            return this.balancePlacements.map((b) => {
                return this.$store.getters['Balances/GET_BALANCE_TOTAL_EQUIVALENT'](this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](b.placementId, b.assetSymbol, b.blockchainName)) ?? 0;
            });
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        assetPrecision() {
            if (this.isAggregatedByPlacements) {
                return 8;
            }
            if (this.maxListPrecision !== null) {
                return this.maxListPrecision;
            }
            return this.$store.getters['Assets/GET_ASSET_DEEP_BY_SYMBOL'](this.balance.assetSymbol);
        },
        total() {
            if (this.isAggregatedByPlacements) {
                return this.balance.assetsBalances.reduce((accum, { totalEquivalent }) => accum + totalEquivalent, 0);
            }
            return this.balance.total;
        },
        free() {
            if (this.isAggregatedByPlacements) {
                return this.balance.assetsBalances.reduce((accum, { freeEquivalent }) => accum + freeEquivalent, 0);
            }
            return this.balance.free;
        },
        totalEquivalent() {
            if (this.isAggregatedByPlacements) {
                return this.balance.assetsBalances.reduce((accum, { totalEquivalent }) => accum + totalEquivalent, 0);
            }
            return this.balance.totalEquivalent || 0;
        },
        summaryHold() {
            if (this.isAggregatedByPlacements) {
                return this.balance.assetsBalances.reduce((accum, { holdEquivalent }) => accum + holdEquivalent, 0);
            }
            if (!this.balance.positionsHold) {
                return Number(this.balance.hold);
            }
            return Number(this.balance.hold) + Number(this.balance.positionsHold);
        },
        totalEquivalentSummary() {
            const value = this.totalEquivalent.floor(this.quotationAssetPrecision).getSeparatedDigits();
            if (Number(value) === 0) {
                if (this.quotationAssetPrecision === 2) {
                    return `< ${this.quotationAssetCharacter}0.01`;
                }
                return `< ${this.quotationAssetCharacter}0.00000001`;
            }
            return `${this.quotationAssetCharacter}${value}`;
        },
    },
    methods: {
        getIsPlacementUnified(placementName) {
            return UnifiedExchangesTags.has(
                this.$store.state.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase()),
            );
        },
        toggleDetails() {
            this.show = !this.show;
        },
        getBalanceTotalEquivalent(balance) {
            const index = balance.quotationAssetSymbols?.findIndex((el) => el === this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']);
            if (typeof index === 'number' && index !== -1) {
                return Number(balance.quotations[index]) * Number(balance.total);
            }
            return 0;
        },
        // this method is needed to call it from the parent component
        closeDetails() {
            this.show = false;
        },
        emitShowModal(e) {
            if (e.placementName) {
                this.$emit('show-modal', e);
            } else {
                this.$emit('show-modal', { ...e, placementName: this.balance.placementName });
            }
        },
        truncateBalancesArray(list) {
            if (list.length <= 8) {
                return list;
            }
            const extraBalances = [];
            const otherElement = {
                placementName: 'Other',
                assetSymbol: 'Other',
                total: 0,
                free: 0,
                hold: 0,
                freeEquivalent: 0,
                totalEquivalent: 0,
                holdEquivalent: 0,
            };
            list = list
                .sort(({ total: a }, { total: b }) => b - a)
                .filter((el, index) => {
                    if (index > 8) {
                        extraBalances.push(el);
                        return false;
                    }
                    return true;
                });
            extraBalances.forEach((b) => {
                otherElement.free += b.free;
                otherElement.total += b.total;
                otherElement.hold += b.hold;
                otherElement.freeEquivalent += b.freeEquivalent;
                otherElement.totalEquivalent += b.totalEquivalent;
                otherElement.holdEquivalent += b.holdEquivalent;
            });
            list.push(otherElement);
            return list;
        },
        truncateBalancesObject(list) {
            let keys = Object.keys(list);
            if (keys.length <= 8) {
                return list;
            }
            const extraKeys = [];
            const otherElement = {
                placementName: 'Other',
                assetSymbol: 'Other',
                total: 0,
                free: 0,
                hold: 0,
            };
            keys = keys.filter((key, index) => {
                if (index > 8) {
                    extraKeys.push(key);
                    return false;
                }
                return true;
            });
            extraKeys.forEach((key) => {
                otherElement.free += list[key].free;
                otherElement.total += list[key].total;
                otherElement.hold += list[key].hold;
            });
            list.Other = otherElement;
            return list;
        },
    },
    watch: {
        isAggregatedByPlacements() {
            this.show = false;
            this.showModal = false;
        },
    },
};
</script>

<style lang="postcss" module="s">
.bold {
    font-weight: var(--fw-bold);
}
.middle {
    vertical-align: middle;
}
.mrXs {
    margin-right: var(--m-xs);
}
.icon {
    transition: transform var(--transition);
    &.rotated {
        transform: rotate(90deg);
    }
}
.pointer {
    cursor: pointer;
}
.w25 {
    width: 25%;
}
.row {
    display: flex;
    align-items: center;
}
.unifiedWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    background-color: var(--cl-gray);
    border-radius: 100px;
    padding: var(--m-ss) var(--m-s);
    font-size: var(--fs-xxs);
    font-weight: var(--fw-bold);
    color: var(--cl-white);
    text-align: center;
    line-height: normal;
}
</style>
